<template>
<div class="row-space-tbf" v-if="loaded">
	<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$route.query.from_salary ? $router.push({ name: 'sick-leaves' }) : $router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'sick-leaves'} })"><icon-arrow-back /></div></div>
	<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title" v-if="$route.query.view == 'true'" >{{ $t('salary.sick-leaves.title_view') }}</h1>
				<h1 class="title" v-else>{{ $route.params.id ? $t('salary.sick-leaves.title_edit') : $t('salary.sick-leaves.title_new')}}</h1>
				<div class="actions">
					<button class="btn-tbf white only-icon" @click="$route.query.from_salary ? $router.push({ name: 'sick-leaves' }) : $router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'sick-leaves'} })"><icon-close class="icon-close" /></button>
				</div>
			</div>
			<show-contract v-if="userData" :data="userData" />
			<choose-contract v-if="!$route.params.contract_id && !$route.params.contract_id" :contract="sick_leaves" :validator="$v.sick_leaves"/>
			<div v-if="sick_leaves.contractSelected || $route.params.contract_id" class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('salary.sick-leaves.title') }}</div>
				</div>
				<div class="form">
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.disease_type') }}*</label>
                            <div v-if="$v.sick_leaves.selectDiseaseType.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.sick_leaves.selectDiseaseType.$error}">
							<div class="icon-left"><icon-handicap /></div>
							<multiselect 
								:disabled="$route.query.view == 'true'"
								v-model="sick_leaves.selectDiseaseType"
								v-bind:class="{populate: sick_leaves.selectDiseaseType != ''}"
								class="select-tags-tbf"
								:options="optionsDisease"
								:allow-empty="false"
								:show-labels="false"
								track-by="id" 
								label="name"
                                @input="sick_leaves.percentage = sick_leaves.selectDiseaseType.percentage, is_from_modal ? calculationMedicalRealisedIncomeBasesModal(sick_leaves.realised_incomes) : calculationMedicalRealisedIncomeBases(false), calculationUnitAndInsuredDays(), enableCodeDisease()"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.sick-leaves.disease_type-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.percentage') }}</label>
						</div>
						<div class="input-box" :class="[{'bg-gray': (sick_leaves.selectDiseaseType.is_editable_percentage == 0 || sick_leaves.percentage == 0) && !$route.query.view},{'bg-white': $route.query.view == 'true'}]">
							<div class="icon-left"><icon-percentage /></div>
							<input :disabled="sick_leaves.selectDiseaseType.is_editable_percentage == 0 || sick_leaves.percentage == '' || $route.query.view == 'true'" placeholder="0" type="text" class="input-text" v-model="sick_leaves.percentage">
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.grant_date') }}*</label>
							<div v-if="$v.sick_leaves.grant_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div v-if="$route.query.view == 'true'" class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<input disabled type="text" class="input-text" :value="moment(sick_leaves.grant_date.selectedDate).format('DD MMM YYYY')">
						</div>
						<div v-else class="input-box" v-bind:class="{has_error: $v.sick_leaves.grant_date.$error}">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right" v-if="Object.keys(sick_leaves.grant_date).length" @click.stop="sick_leaves.grant_date = {}"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="sick_leaves.grant_date"
								class="calendar-tbf"
								:configs="calendarConfigs"
								disabled
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="props.selectedDate ? $options.filters.moment(props.selectedDate, 'DD MMM YYYY') : sick_leaves.grant_date = {}" 
								v-if="Object.keys(sick_leaves.grant_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.sick-leaves.grant_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.vacation.start_date') }}*</label>
							<div v-if="$v.sick_leaves.start_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div v-if="$route.query.view == 'true'" class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<input disabled type="text" class="input-text" :value="moment(sick_leaves.start_date.selectedDate).format('DD MMM YYYY')">
						</div>
						<div v-else class="input-box" v-bind:class="{has_error: $v.sick_leaves.start_date.$error}">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right" v-if="Object.keys(sick_leaves.start_date).length" @click.stop="sick_leaves.start_date = {}"><icon-close class="icon-clear"/></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="sick_leaves.start_date"
								class="calendar-tbf"
								:configs="calendarConfigs"
                                @input="calculateWorkingDays(), is_from_modal ? calculationMedicalRealisedIncomeBasesModal(sick_leaves.realised_incomes) : calculationMedicalRealisedIncomeBases(false), calculationRealisedIncomeMaxLimit(), calculationUnitAndInsuredDays(), changeEndDate()"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="props.selectedDate ? $options.filters.moment(props.selectedDate, 'DD MMM YYYY') : sick_leaves.start_date = {}" 
								v-if="Object.keys(sick_leaves.start_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.vacation.start_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.vacation.final_date') }}*</label>
							<div v-if="$v.sick_leaves.final_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div v-if="$route.query.view == 'true'" class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<input disabled type="text" class="input-text" :value="moment(sick_leaves.final_date.selectedDate).format('DD MMM YYYY')">
						</div>
						<div v-else class="input-box" v-bind:class="{has_error: $v.sick_leaves.final_date.$error}">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right" v-if="Object.keys(sick_leaves.final_date).length" @click.stop="sick_leaves.final_date = {}"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="sick_leaves.final_date"
								class="calendar-tbf end_date"
								:configs="calendarConfigsEndData"
                                @input="calculateWorkingDays(), calculationUnitAndInsuredDays()"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="props.selectedDate ? $options.filters.moment(props.selectedDate, 'DD MMM YYYY') : sick_leaves.final_date = {}" 
								v-if="Object.keys(sick_leaves.final_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.vacation.final_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                        <div class="label-header">
                            <label class="label">{{ $t('salary.sick-leaves.realeased_on_discarged') }}</label>
                        </div>
						<div v-if="$route.query.view == 'true'" class="input-box bor-0">
                            <div class="checkbox-list">
                                <div class="checkbox-item m-4" v-bind:class="{active: sick_leaves.realeased_on_discarged === 0}">
                                    <div class="name">{{ $t('create-user.no') }}</div>
                                </div>
                                <div class="checkbox-item m-4" v-bind:class="{active: sick_leaves.realeased_on_discarged === 1}">
                                    <div class="name">{{ $t('create-user.yes') }}</div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="input-box bor-0">
                            <div class="checkbox-list">
                                <div class="checkbox-item m-4" @click="sick_leaves.realeased_on_discarged = 0" v-bind:class="{active: sick_leaves.realeased_on_discarged === 0}">
                                    <div class="name">{{ $t('create-user.no') }}</div>
                                </div>
                                <div class="checkbox-item m-4" @click="sick_leaves.realeased_on_discarged = 1" v-bind:class="{active: sick_leaves.realeased_on_discarged === 1}">
                                    <div class="name">{{ $t('create-user.yes') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="delimeter-form"></div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.sick_leave_prescription_place') }}*</label>
							<div v-if="$v.sick_leaves.selectPrescriptionPlace.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.sick_leaves.selectPrescriptionPlace.$error}">
							<div class="icon-left"><icon-medical /></div>
							<multiselect 
								:disabled="$route.query.view == 'true'"
								v-model="sick_leaves.selectPrescriptionPlace"
								v-bind:class="{populate: sick_leaves.selectPrescriptionPlace != ''}"
								class="select-tags-tbf"
								:options="optionsPrescription"
								:allow-empty="false"
								:show-labels="false"
								track-by="id" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.sick-leaves.sick_leave_prescription_place-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.diagnostic_code') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-handicap /></div>
							<input :disabled="$route.query.view == 'true'" type="text" :placeholder="$t('salary.sick-leaves.diagnostic_code-ph')" class="input-text" v-model="sick_leaves.diagnostic_code">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.expert_approval_number') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-handicap /></div>
							<input :disabled="$route.query.view == 'true'" type="text" :placeholder="$t('salary.sick-leaves.expert_approval_number-ph')" class="input-text" v-model="sick_leaves.expert_approval_number">
						</div>
					</div>
					<div v-if="sick_leaves.selectDiseaseType.id === 9 || sick_leaves.selectDiseaseType.id === 17" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.child_cnp') }}</label>
							<div v-if="$v.sick_leaves.child_cnp.$error" class="error-msg">{{ $t('validator.required')}}</div>
							<div v-if="!$v.sick_leaves.child_cnp.$error && error_cnp" class="error-msg">{{ $t('validator.required_cnp')}}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.sick_leaves.child_cnp.$error || error_cnp}">
							<div class="icon-left"><icon-cnp /></div>
							<input :disabled="$route.query.view == 'true'" @input="error_cnp = false" type="text" :placeholder="$t('salary.sick-leaves.child_cnp-ph')" class="input-text" v-model="sick_leaves.child_cnp">
						</div>
					</div>
                    <div class="delimeter-form"></div>
					 <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.series') }}*</label>
                            <div v-if="$v.sick_leaves.series.$error" class="error-msg">{{ $t('validator.required') }}</div>
							<div v-if="!$v.sick_leaves.series.$error && error_series" class="error-msg">{{ $t('validator.required_series')}}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.sick_leaves.series.$error || error_series}">
							<div class="icon-left"><icon-handicap /></div>
							<input :disabled="$route.query.view == 'true'" @input="error_series = false" type="text" :placeholder="$t('salary.sick-leaves.series-ph')" class="input-text" v-model="sick_leaves.series">
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.number') }}*</label>
                             <div v-if="$v.sick_leaves.series.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.sick_leaves.number.$error}">
							<div class="icon-left"><icon-handicap /></div>
							<input :disabled="$route.query.view == 'true'" type="text" :placeholder="$t('salary.sick-leaves.number-ph')" class="input-text" v-model="sick_leaves.number">
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                        <div class="label-header">
                            <label class="label">{{ $t('salary.sick-leaves.is_continuation') }}</label>
                        </div>
						<div v-if="$route.query.view == 'true'" class="input-box bor-0">
                            <div class="checkbox-list">
                                <div class="checkbox-item m-4" v-bind:class="{active: sick_leaves.is_continuation === 0}">
                                    <div class="name">{{ $t('create-user.no') }}</div>
                                </div>
                                <div class="checkbox-item m-4" v-bind:class="{active: sick_leaves.is_continuation === 1}">
                                    <div class="name">{{ $t('create-user.yes') }}</div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="input-box bor-0">
                            <div class="checkbox-list">
                                <div class="checkbox-item m-4" @click="sick_leaves.is_continuation = 0, calculationUnitAndInsuredDays()" v-bind:class="{active: sick_leaves.is_continuation === 0}">
                                    <div class="name">{{ $t('create-user.no') }}</div>
                                </div>
                                <div class="checkbox-item m-4" @click="sick_leaves.is_continuation = 1, calculationUnitAndInsuredDays()" v-bind:class="{active: sick_leaves.is_continuation === 1}">
                                    <div class="name">{{ $t('create-user.yes') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="sick_leaves.is_continuation" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.initial_grant_date') }}</label>
                            <div v-if="$v.sick_leaves.initial_grant_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div v-if="$route.query.view == 'true'" class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<input disabled type="text" class="input-text" v-model="moment(sick_leaves.initial_grant_date.selectedDate).format('DD MMM YYYY')">
						</div>
						<div v-else class="input-box" v-bind:class="{has_error: $v.sick_leaves.initial_grant_date.$error}">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right" v-if="Object.keys(sick_leaves.initial_grant_date).length" @click.stop="sick_leaves.initial_grant_date = {}"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="sick_leaves.initial_grant_date"
								class="calendar-tbf"
								:configs="calendarConfigs"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(sick_leaves.initial_grant_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.sick-leaves.initial_grant_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
                    <div v-if="sick_leaves.is_continuation" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.initial_series') }}</label>
                            <div v-if="$v.sick_leaves.initial_series.$error" class="error-msg">{{ $t('validator.required') }}</div>
							<div v-if="!$v.sick_leaves.initial_series.$error && error_initial_series" class="error-msg">{{ $t('validator.required_series')}}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.sick_leaves.initial_series.$error || error_initial_series}">
							<div class="icon-left"><icon-handicap /></div>
							<input :disabled="$route.query.view == 'true'" type="text" @change="validateUnitAndInsuranceDays(), calculationMedicalRealisedIncomeBases(false)" @input="error_initial_series = false" :placeholder="$t('salary.sick-leaves.initial_series-ph')" class="input-text" v-model="sick_leaves.initial_series">
						</div>
					</div>
                    <div v-if="sick_leaves.is_continuation" style="margin-bottom: 60px" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.initial_number') }}</label>
                            <div v-if="$v.sick_leaves.initial_number.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.sick_leaves.initial_number.$error}">
							<div class="icon-left"><icon-handicap /></div>
							<input :disabled="$route.query.view == 'true'" type="text" @change="validateUnitAndInsuranceDays(), calculationMedicalRealisedIncomeBases(false)" :placeholder="$t('salary.sick-leaves.initial_number-ph')" class="input-text" v-model="sick_leaves.initial_number">
						</div>
					</div>
                    <div class="delimeter-form"></div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.condition_type') }}*</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-handicap /></div>
							<multiselect 
								:disabled="$route.query.view == 'true'"
								v-model="sick_leaves.selectedCondition"
								v-bind:class="{populate: sick_leaves.selectedCondition != ''}"
								class="select-tags-tbf"
								:options="optionsCondition"
								:allow-empty="false"
								:show-labels="false"
								track-by="id" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.sick-leaves.condition_type-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.sick_leave_urgent') }}</label>
							<div v-if="$v.sick_leaves.selectUrgentId.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="[{'bg-gray': sick_leaves.selectDiseaseType.code !== '06' && !$route.query.view}, {has_error: $v.sick_leaves.selectUrgentId.$error}, {'bg-white': $route.query.view == 'true'}]">
							<div class="icon-left"><icon-handicap /></div>
							<div class="icon-right" v-if="sick_leaves.selectUrgentId != ''" @click.stop="sick_leaves.selectUrgentId = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								v-model="sick_leaves.selectUrgentId"
								v-bind:class="{populate: sick_leaves.selectUrgentId != ''}"
								class="select-tags-tbf"
								:options="optionsUrgent"
								:allow-empty="false"
								:show-labels="false"
								track-by="id" 
								label="name"
								:disabled="sick_leaves.selectDiseaseType.code !== '06' || $route.query.view == 'true'"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.sick-leaves.sick_leave_urgent-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.sick_leave_infectious_disease') }}</label>
						</div>
						<div class="input-box" v-bind:class="[{'bg-gray': sick_leaves.selectDiseaseType.code !== '05' && !$route.query.view}, {'bg-white': $route.query.view == 'true'}]">
							<div class="icon-left"><icon-handicap /></div>
							<div class="icon-right" v-if="sick_leaves.selectInfectionDisease != ''" @click.stop="sick_leaves.selectInfectionDisease = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								v-model="sick_leaves.selectInfectionDisease"
								v-bind:class="{populate: sick_leaves.selectInfectionDisease != ''}"
								class="select-tags-tbf"
								:options="optionsInfectiousDisease"
								:allow-empty="true"
								:show-labels="false"
								track-by="id" 
								label="name"
								:disabled="sick_leaves.selectDiseaseType.code !== '05' || $route.query.view == 'true'"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.sick-leaves.sick_leave_infectious_disease-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.sick_leave_infectious_disease_isolation') }}</label>
						</div>
						<div class="input-box" v-bind:class="[{'bg-gray': sick_leaves.selectDiseaseType.code !== '51' && !$route.query.view}, {'bg-white': $route.query.view == 'true'}]">
							<div class="icon-left"><icon-handicap /></div>
							<div class="icon-right" v-if="sick_leaves.selectInfectionDiseaseIsolation != ''" @click.stop="sick_leaves.selectInfectionDiseaseIsolation = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								v-model="sick_leaves.selectInfectionDiseaseIsolation"
								v-bind:class="{populate: sick_leaves.selectInfectionDiseaseIsolation != ''}"
								class="select-tags-tbf"
								:options="optionsInfectiousDiseaseIsolation"
								:allow-empty="true"
								:show-labels="false"
								track-by="id" 
								label="name"
								:disabled="sick_leaves.selectDiseaseType.code !== '51' || $route.query.view == 'true'"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.sick-leaves.sick_leave_infectious_disease_isolation-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
				</div>
			</div>
            <div v-if="sick_leaves.contractSelected || $route.params.contract_id" class="form-group">
				<div class="data-group">
					<div class="title">{{$t('salary.sick-leaves.title_base')}}</div>
				</div>
				<div class="form">
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.calendar_days') }}</label>
						</div>
						<div class="input-box" v-bind:class="[{'bg-gray': !$route.query.view}, {'bg-white': $route.query.view == 'true'}]">
							<div class="icon-left"><icon-calendar /></div>
							<input disabled type="text" class="input-text" v-model="sick_leaves.calendar_days">
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.working_days') }}</label>
						</div>
						<div class="input-box" v-bind:class="[{'bg-gray': !$route.query.view}, {'bg-white': $route.query.view == 'true'}]">
							<div class="icon-left"><icon-calendar /></div>
							<input disabled type="text" class="input-text" v-model.number="sick_leaves.working_days">
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.realised_income_max_limit') }}</label>
						</div>
						<div class="input-box" v-bind:class="[{'bg-gray': !$route.query.view}, {'bg-white': $route.query.view == 'true'}]">
							<div class="icon-left"><icon-coins /></div>
							<input disabled type="text" class="input-text" v-model="sick_leaves.realised_income_max_limit">
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.calculation_base_amount') }}</label>
                            <div v-if="Object.keys(this.sick_leaves.start_date).length" class="create-new" @click="showModal('medical', {from: 'sick-leaves', model:sick_leaves.realised_incomes})">{{ $t('salary.sick-leaves.add_base_calculation') }}</div>
						</div>
						<div class="input-box" v-bind:class="[{'bg-gray': !$route.query.view}, {'bg-white': $route.query.view == 'true'}]">
							<div class="icon-left"><icon-coins /></div>
							<input disabled type="text" class="input-text" v-model="sick_leaves.calculation_base_amount">
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.calculation_base_days') }}</label>
						</div>
						<div class="input-box" v-bind:class="[{'bg-gray': !$route.query.view}, {'bg-white': $route.query.view == 'true'}]">
							<div class="icon-left"><icon-calendar /></div>
							<input disabled type="text" class="input-text" v-model="sick_leaves.calculation_base_days">
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.daily_average_amount') }}</label>
						</div>
						<div class="input-box" v-bind:class="[{'bg-gray': !$route.query.view}, {'bg-white': $route.query.view == 'true'}]">
							<div class="icon-left"><icon-coins /></div>
							<input disabled type="text" class="input-text" v-model.number="sick_leaves.daily_average_amount">
						</div>
					</div>
                     <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.employer_days') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<input :disabled="$route.query.view == 'true'" @keypress="onlyNumber" @change="ModifiedDividedDays()" type="text" :placeholder="$t('salary.sick-leaves.employer_days-ph')" class="input-text" v-model.number="sick_leaves.employer_days">
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.insurance_days') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<input :disabled="$route.query.view == 'true'" @keypress="onlyNumber" @change="ModifiedDividedDays()" type="text" :placeholder="$t('salary.sick-leaves.insurance_days-ph')" class="input-text" v-model.number="sick_leaves.insurance_days">
						</div>
					</div>
					<!-- is condition -->
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.employer_amount') }}</label>
						</div>
						<div class="input-box" v-bind:class="[{'bg-gray': !$route.query.view}, {'bg-white': $route.query.view == 'true'}]">
							<div class="icon-left"><icon-coins /></div>
							<input disabled type="text" class="input-text" v-model="sick_leaves.employer_amount">
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.sick-leaves.insurance_amount') }}</label>
						</div>
						<div class="input-box" v-bind:class="[{'bg-gray': !$route.query.view}, {'bg-white': $route.query.view == 'true'}]">
							<div class="icon-left"><icon-coins /></div>
							<input disabled type="text" class="input-text" v-model="sick_leaves.insurance_amount">
						</div>
					</div>
                </div>
            </div>
		</div>
		<div class="space-right"></div>
		<div class="form-submit crud">
			<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
			<button class="btn-tbf grey" @click="$route.query.from_salary ? $router.push({ name: 'sick-leaves' }) : $router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'sick-leaves'} })"><span class="text">{{ $t('general.cancel') }}</span></button>
			<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')" v-if="!$route.params.id">
				<div class="loader"></div>
				<span class="text">{{ $t('general.add') }}</span>
			</button>
			<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')" v-else-if="!$route.query.view">
				<div class="loader"></div>
				<span class="text">{{ $t('general.update') }}</span>
			</button>
			<button id="buttonUpdate" class="btn-tbf blue" @click="$router.push({ name: 'sick-leaves-edit', params: {id: $route.params.id, contract_id: $route.params.contract_id} })" v-else-if="$route.query.view == 'true'">
				<div class="loader"></div>
				<span class="text">{{ $t('general.edit') }}</span>
			</button>
		</div>
	</div>
	<loader-vacation-create v-else/>
</template>

<script>
	import ShowContract from  './ShowContract'
	import ChooseContract from  './ChooseContract'
	import IconHandicap from '../../../../Icons/Handicap'
	import IconPercentage from '../../../../Icons/Percentage'
	import IconMedical from '../../../../Icons/Medical'
	import IconCnp from '../../../../Icons/Cnp'
	import IconCoins from '../../../../Icons/Coins'
	import IconCalendar from '../../../../Icons/Calendar'
	import IconClose from '../../../../Icons/Close'
	import IconArrowBack from '../../../../Icons/ArrowLong'
	import LoaderVacationCreate from '../../../../PagesLoaders/UsersCreate'
	import IconUser from '../../../../Icons/User'
    import { required, requiredIf } from 'vuelidate/lib/validators'

	export default {
		components: {
			ShowContract,
			ChooseContract,
			IconHandicap,
			IconPercentage,
			IconMedical,
			IconCnp,
			IconCoins,
			IconCalendar,
			IconClose,
			IconArrowBack,
			IconUser,
			LoaderVacationCreate
		},
		data() {
			return {
				userData: {},
				loaded: false,
				calendarConfigs: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true
				},
				calendarConfigsEndData: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					limits: {min: '', max : ''}
				},
				error_message: '',
                optionsPrescription: [],
                optionsDisease: [],
                optionsUrgent: [],
                optionsInfectiousDisease: [],
                optionsInfectiousDiseaseIsolation: [],
                optionsCondition: [{id:1, name: this.$t('salary.sick-leaves.is_normal_condition'), value: 1}, {id:2, name:this.$t('salary.sick-leaves.is_particular_condition'), value: 1}, {id:3, name:this.$t('salary.sick-leaves.is_special_condition'), value: 1}],
                sick_leaves: {
					contractSelected: '',
                    selectDiseaseType: '',
                    percentage: '',
                    grant_date: {},
					start_date: {},
					final_date: {},
                    realeased_on_discarged: 0,
                    selectPrescriptionPlace: '',
                    diagnostic_code: '',
					expert_approval_number: '',
					child_cnp: '',
                    series: '',
                    number: '',
                    is_continuation: 0,
                    initial_grant_date: {},
                    initial_series: '',
                    initial_number: '',
                    is_normal_condition: 1,
			        is_particular_condition: 0,
			        is_special_condition: 0,
                    selectUrgentId: '',
                    selectInfectionDisease: '',
                    selectInfectionDiseaseIsolation: '',
                    employer_days: '',
                    insurance_days: '',
                    calendar_days: 0,
                    working_days: 0,
                    daily_average_amount: 0,
                    realised_income_max_limit: 0,
                    calculation_base_amount: 0,
                    calculation_base_days: 0,
                    employer_amount: 0,
                    insurance_amount: 0,
                    realised_incomes: [],
                    is_modified_divided_days: 0,
                    is_modified_realised_base: 0,
                    selectedCondition: {id:1, name:this.$t('salary.sick-leaves.is_normal_condition'), value: 1}
                },
                is_from_modal: false,
				error_cnp: false,
				error_series: false,
				error_initial_series: false
			}
		},
        validations: {
            sick_leaves: {
				contractSelected: {
                    required: requiredIf(function () {
                        return !this.$route.params.id && !this.$route.params.contract_id
                    })
			    },
				grant_date: { required },
				start_date: { required },
				final_date: { required },
				selectPrescriptionPlace: { required },
                selectDiseaseType: { required },
				child_cnp: {
                    required: requiredIf(function () {
                        return this.sick_leaves.selectDiseaseType.id === 9 || this.sick_leaves.selectDiseaseType.id === 17
                    })
			    },
                series: { required },
                number: { required },
                initial_grant_date: {
                    required: requiredIf(function () {
                        return this.sick_leaves.is_continuation === 1
                    })
			    },
				selectUrgentId: {
                    required: requiredIf(function () {
                        return this.sick_leaves.selectDiseaseType.code == "06"
                    })
			    },
                initial_series: {
                    required: requiredIf(function () {
                        return this.sick_leaves.is_continuation === 1
                    })
			    },
                initial_number: {
                    required: requiredIf(function () {
                        return this.sick_leaves.is_continuation === 1
                    })
			    },
            }
        },
		watch: {
			'sick_leaves.insurance_amount'() {
				this.sick_leaves.insurance_amount = this.sick_leaves.insurance_amount.toLocaleString()
			},
			'sick_leaves.employer_amount'() {
				this.sick_leaves.employer_amount = this.sick_leaves.employer_amount.toLocaleString()
			},
			'sick_leaves.daily_average_amount'() {
				this.sick_leaves.daily_average_amount = this.sick_leaves.daily_average_amount.toLocaleString()
			},
			'sick_leaves.realised_income_max_limit'() {
				this.sick_leaves.realised_income_max_limit = this.sick_leaves.realised_income_max_limit ? this.sick_leaves.realised_income_max_limit.toLocaleString() : ''
			}
		},
		async mounted(){
			this.userData = localStorage.userData ? JSON.parse(localStorage.userData) : '';
            await this.getOptionsPrescription();
			if(this.$route.params.id){
				await this.getSickLeavesData()
			}

            this.$root.$on('medicalReleasedIncomeBase', async data => {
				if(data.length > 0) {
					let modified = []
					modified = data.filter(el => el.is_modified == 1)
					if(modified.length > 0) {
						this.sick_leaves.is_modified_realised_base = 1
					}
					return this.calculationMedicalRealisedIncomeBasesModal(data)
				}else {
					this.is_from_modal = false
					this.sick_leaves.is_modified_realised_base = 0
					await this.calculationMedicalRealisedIncomeBases(true)
					return this.$root.$emit('medicalReleasedIncomeBaseModal', this.sick_leaves.realised_incomes);
					
				}
				
            })
			this.$root.$on('updateSickLeavesCalculation', () => {
				this.calculateWorkingDays();
				this.calculationMedicalRealisedIncomeBases(false);
				this.calculationRealisedIncomeMaxLimit();
				this.calculationUnitAndInsuredDays();
			})
		},
        methods: {
			onlyNumber ($event) {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                if (keyCode < 48 || keyCode > 57) { 
                    $event.preventDefault();
                }
            },
            async getOptionsPrescription() {
                await axios.get('/sick-leave-form-noms').then(({data}) => {
					this.optionsPrescription = data.data.sick_leave_prescription_places
                    this.optionsDisease = data.data.disease_types.map(el => {
						return {name: el.code + ' - ' + el.name, code: el.code, id: el.id, percentage: el.percentage, is_editable_percentage: el.is_editable_percentage}
					})
                    this.optionsUrgent = data.data.sick_leave_urgents.map(el => {
						return {name: el.code + ' - ' + el.name, code: el.code, id: el.id}
					})
                    this.optionsInfectiousDisease = data.data.sick_leave_infectious_diseases.map(el => {
						return {name: el.code + ' - ' + el.name, code: el.code, id: el.id}
					})
                    this.optionsInfectiousDiseaseIsolation = data.data.sick_leave_infectious_disease_isolations.map(el => {
						return {name: el.code + ' - ' + el.name, code: el.code, id: el.id}
					})
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
					if(!this.$route.params.id){
						setTimeout(() => {
							this.loaded = true
							setTimeout(() => {
								$('.opacity-page').addClass('show')
							}, 0)
						}, 0)
					}
				})
            },
			enableCodeDisease(){
				if(this.sick_leaves.selectDiseaseType.code !== '05') {
					this.sick_leaves.selectInfectionDisease = ''
				}
				if(this.sick_leaves.selectDiseaseType.code !== '06') {
					this.sick_leaves.selectUrgentId = ''
				}
				if(this.sick_leaves.selectDiseaseType.code !== '51') {
					this.sick_leaves.selectInfectionDiseaseIsolation = ''
				}
			},
			async getSickLeavesData() {
				await axios.get(`/sick-leaves/${this.$route.params.id}`).then(({data}) => {
                    if(data.data.grant_date){
						this.sick_leaves.grant_date = {
							selectedDate: data.data.grant_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.start_date){
						this.sick_leaves.start_date = {
							selectedDate: data.data.start_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
					if(this.$route.query.view == 'true') {
						this.calculateWorkingDays(), this.calculationMedicalRealisedIncomeBases(false), this.calculationRealisedIncomeMaxLimit(), this.calculationUnitAndInsuredDays(), this.changeEndDate()
					}
                    if(data.data.end_date){
						this.sick_leaves.final_date = {
							selectedDate: data.data.end_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.initial_grant_date){
						this.sick_leaves.initial_grant_date = {
							selectedDate: data.data.initial_grant_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    this.sick_leaves.selectDiseaseType = data.data.disease_type_id ? this.optionsDisease.find(el => el.id == data.data.disease_type_id) : ''
                    this.sick_leaves.percentage = data.data.percentage
                    this.sick_leaves.realeased_on_discarged = data.data.released_on_discharge
                    this.sick_leaves.selectPrescriptionPlace = data.data.sick_leave_prescription_place_id ? this.optionsPrescription.find(el => el.id == data.data.sick_leave_prescription_place_id) : ''
                    this.sick_leaves.diagnostic_code = data.data.diagnostic_code
					this.sick_leaves.expert_approval_number = data.data.expert_approval_number
					this.sick_leaves.child_cnp = data.data.child_cnp 
                    this.sick_leaves.series = data.data.series
                    this.sick_leaves.number = data.data.number
                    this.sick_leaves.is_continuation = data.data.is_continuation
                    this.sick_leaves.initial_series = data.data.initial_series
                    this.sick_leaves.initial_number = data.data.initial_number
					this.sick_leaves.is_normal_condition = data.data.is_normal_condition
					this.sick_leaves.is_particular_condition = data.data.is_particular_condition;
					this.sick_leaves.is_special_condition = data.data.is_special_condition
					this.sick_leaves.selectedCondition = data.data.is_normal_condition ? this.optionsCondition[0] : data.data.is_particular_condition ? this.optionsCondition[1] : data.data.is_special_condition ? this.optionsCondition[2] : ''
                    this.sick_leaves.selectUrgentId = data.data.sick_leave_urgent_id ? this.optionsUrgent.find(el => el.id == data.data.sick_leave_urgent_id) : ''
                    this.sick_leaves.selectInfectionDisease = data.data.sick_leave_infectious_disease_id ? this.optionsInfectiousDisease.find(el => el.id == data.data.sick_leave_infectious_disease_id) : ''
                    this.sick_leaves.selectInfectionDiseaseIsolation = data.data.sick_leave_infectious_disease_isolation_id ? this.optionsInfectiousDiseaseIsolation.find(el => el.id == data.data.sick_leave_infectious_disease_isolation_id) : ''
                    this.sick_leaves.employer_days = data.data.employer_days
                    this.sick_leaves.insurance_days = data.data.insurance_days
                    this.sick_leaves.calendar_days = data.data.calendar_days
                    this.sick_leaves.working_days = data.data.working_days
                    this.sick_leaves.daily_average_amount = data.data.daily_average_amount
                    this.sick_leaves.realised_income_max_limit = data.data.realised_income_max_limit
                    this.sick_leaves.calculation_base_amount = data.data.calculation_base_amount
                    this.sick_leaves.calculation_base_days = data.data.calculation_base_days
                    this.sick_leaves.employer_amount = data.data.employer_amount
                    this.sick_leaves.insurance_amount = data.data.insurance_amount
                    this.sick_leaves.is_modified_divided_days = data.data.is_modified_divided_days
                    this.sick_leaves.is_modified_realised_base = data.data.is_modified_realised_base
					setTimeout(() => {
						if(data.data.realisedIncomes) {
							this.sick_leaves.realised_incomes = data.data.realisedIncomes
							this.calculationMedicalRealisedIncomeBasesModal(data.data.realisedIncomes)
						}
					}, 1000);
					
                }).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
			},
            ModifiedDividedDays() {
                this.sick_leaves.is_modified_divided_days = 1;
            },
            async calculateWorkingDays() {
				if(this.sick_leaves.start_date.selectedDate && this.sick_leaves.final_date.selectedDate){
					var start = moment(this.sick_leaves.start_date.selectedDate, "YYYY-MM-DD");
					var end = moment(this.sick_leaves.final_date.selectedDate, "YYYY-MM-DD");
					this.sick_leaves.calendar_days = moment.duration(end.diff(start)).asDays()+1

					await axios.get('/working-days-service', {params: {start_date: this.sick_leaves.start_date.selectedDate , end_date: this.sick_leaves.final_date.selectedDate}}).then(({data}) => {
						this.sick_leaves.working_days = data.data.days
					})
				}
			},
            calculationMedicalRealisedIncomeBasesModal(data){
                this.is_from_modal = true;
                this.sick_leaves.realised_incomes = data
                this.sick_leaves.calculation_base_amount = data.reduce((total, obj) => obj.realised_income+total,0).toFixed(0)
                this.sick_leaves.calculation_base_days = data.reduce((total, obj) => obj.realised_days + total,0)
                if(this.sick_leaves.calculation_base_days != 0 && this.sick_leaves.calculation_base_amount != 0) {
                    this.sick_leaves.daily_average_amount = ((this.sick_leaves.calculation_base_amount/this.sick_leaves.calculation_base_days)*(this.sick_leaves.percentage/100)).toFixed(2)
					if(this.sick_leaves.daily_average_amount) {
						this.sick_leaves.employer_amount = Math.round(this.sick_leaves.employer_days*this.sick_leaves.daily_average_amount)
                    	this.sick_leaves.insurance_amount = Math.round(this.sick_leaves.insurance_days*this.sick_leaves.daily_average_amount)
					}
                } else {
					this.sick_leaves.daily_average_amount = 0
                    this.sick_leaves.employer_amount = 0
                    this.sick_leaves.insurance_amount = 0
				}
            },
            async calculationMedicalRealisedIncomeBases(reset){
                if(!this.is_from_modal && !this.sick_leaves.is_modified_realised_base) {
					let objData = this.sick_leaves.is_continuation ? {
                        start_date: this.sick_leaves.start_date.selectedDate,
                        initial_series: this.sick_leaves.initial_series,
                        initial_number: this.sick_leaves.initial_number
                    } : {
                        start_date: this.sick_leaves.start_date.selectedDate
                    }
					if(this.$route.params.id) {
						objData.sick_leave_id = this.$route.params.id
					}
					if(reset){
						objData.reset = true
					}
					if(this.sick_leaves.start_date.selectedDate) {
						await axios.get(`${this.$route.params.contract_id ? this.$route.params.contract_id : this.sick_leaves.contractSelected.id}/medical-realised-income-bases/create`, {params: objData}).then(({data}) => {
						this.sick_leaves.realised_incomes = data.data
						this.sick_leaves.calculation_base_amount = data.data.reduce((total, obj) => obj.realised_income + total,0).toFixed(0)
						this.sick_leaves.calculation_base_days = data.data.reduce((total, obj) => obj.realised_days + total,0)
							if(this.sick_leaves.calculation_base_days != 0 && this.sick_leaves.calculation_base_amount != 0) {
								this.sick_leaves.daily_average_amount = ((this.sick_leaves.calculation_base_amount/this.sick_leaves.calculation_base_days)*(this.sick_leaves.percentage/100)).toFixed(2)
								if(this.sick_leaves.daily_average_amount) {
									this.sick_leaves.employer_amount =  Math.round(this.sick_leaves.employer_days*this.sick_leaves.daily_average_amount)
									this.sick_leaves.insurance_amount = Math.round(this.sick_leaves.insurance_days*this.sick_leaves.daily_average_amount)
								}
							} else {
								this.sick_leaves.daily_average_amount = 0
								this.sick_leaves.employer_amount = 0
								this.sick_leaves.insurance_amount = 0
							}
                   	 	})
					}
                    
                }
            },
            async calculationRealisedIncomeMaxLimit(){
                if(this.sick_leaves.start_date.selectedDate) {
                    await axios.get('/realised-income-max-limit', {params: {date: this.sick_leaves.start_date.selectedDate}}).then(({data}) => {
                        this.sick_leaves.realised_income_max_limit = data.data.max_limit_amount
                    })
                }
            },
			async validateUnitAndInsuranceDays() {
				if(this.sick_leaves.initial_series && this.sick_leaves.initial_series.length == 5 && this.sick_leaves.initial_number && this.sick_leaves.initial_number.length >= 6) {
					await this.calculationUnitAndInsuredDays()
				}
			},
            async calculationUnitAndInsuredDays() {
                if((this.sick_leaves.selectDiseaseType && Object.keys(this.sick_leaves.start_date).length && Object.keys(this.sick_leaves.final_date).length) && (this.$route.params.contract_id || this.sick_leaves.contractSelected.id)) {
                    let objData = this.sick_leaves.is_continuation ? {
                        contract_id: this.$route.params.contract_id ? this.$route.params.contract_id : this.sick_leaves.contractSelected.id, 
                        disease_type_id: this.sick_leaves.selectDiseaseType.id,
                        start_date: this.sick_leaves.start_date.selectedDate,
                        end_date: this.sick_leaves.final_date.selectedDate,
                        initial_series: this.sick_leaves.initial_series,
                        initial_number: this.sick_leaves.initial_number
                    } : {
                        contract_id: this.$route.params.contract_id ? this.$route.params.contract_id : this.sick_leaves.contractSelected.id, 
                        disease_type_id: this.sick_leaves.selectDiseaseType.id,
                        start_date: this.sick_leaves.start_date.selectedDate,
                        end_date: this.sick_leaves.final_date.selectedDate
                    }
                    this.sick_leaves.is_modified_divided_days = 0;
                    await axios.get('/sick-leave-days-division', {params: objData}).then(({data}) => {
                        this.sick_leaves.employer_days = data.employer_days
                        this.sick_leaves.insurance_days = data.insurance_days
						if(this.sick_leaves.daily_average_amount) {
							this.sick_leaves.employer_amount =  Math.round(this.sick_leaves.employer_days*this.sick_leaves.daily_average_amount)
							this.sick_leaves.insurance_amount = Math.round(this.sick_leaves.insurance_days*this.sick_leaves.daily_average_amount)
						}
                    })
                }
            },
			changeEndDate() {
				this.sick_leaves.final_date = {}
				this.calendarConfigsEndData.limits.min = this.sick_leaves.start_date.selectedDate
				const lastDay = moment(this.sick_leaves.start_date.selectedDate, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD')
				this.calendarConfigsEndData.limits.max = lastDay
			},
			saveAction(type) {
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';
				this.error_cnp = false;
				this.error_series = false;
				this.error_initial_series = false

				if(this.sick_leaves.child_cnp && this.sick_leaves.child_cnp.length !== 13) {
					this.error_cnp = true;
				}
				if(this.sick_leaves.series && this.sick_leaves.series.length !== 5) {
					this.error_series = true;
				}
				if(this.sick_leaves.initial_series && this.sick_leaves.initial_series.length < 5) {
					this.error_initial_series = true;
				}

				this.$v.$touch()
                if(!this.$v.$invalid){
					let objData = {}

                    if(Object.keys(this.sick_leaves.grant_date).length){
						objData.grant_date = this.sick_leaves.grant_date.selectedDate
					}
					if(Object.keys(this.sick_leaves.start_date).length){
						objData.start_date = this.sick_leaves.start_date.selectedDate
					}
					if(Object.keys(this.sick_leaves.final_date).length){
						objData.end_date = this.sick_leaves.final_date.selectedDate
					}
                    if(Object.keys(this.sick_leaves.initial_grant_date).length){
						objData.initial_grant_date = this.sick_leaves.is_continuation ? this.sick_leaves.initial_grant_date.selectedDate : null
					}

					objData.contract_id = this.$route.params.contract_id ? this.$route.params.contract_id : this.sick_leaves.contractSelected.id
                    objData.disease_type_id = this.sick_leaves.selectDiseaseType ? this.sick_leaves.selectDiseaseType.id : ''
                    objData.percentage = this.sick_leaves.percentage
                    objData.released_on_discharge = this.sick_leaves.realeased_on_discarged
                    objData.sick_leave_prescription_place_id = this.sick_leaves.selectPrescriptionPlace ? this.sick_leaves.selectPrescriptionPlace.id : ''
                    objData.diagnostic_code = this.sick_leaves.diagnostic_code
					objData.expert_approval_number = this.sick_leaves.expert_approval_number 
					objData.child_cnp = this.sick_leaves.child_cnp
                    objData.series = this.sick_leaves.series
                    objData.number = this.sick_leaves.number
                    objData.is_continuation = this.sick_leaves.is_continuation
                    objData.initial_series = this.sick_leaves.is_continuation ? this.sick_leaves.initial_series : null
                    objData.initial_number = this.sick_leaves.is_continuation ? this.sick_leaves.initial_number : null
                    objData.is_normal_condition = this.sick_leaves.selectedCondition.id == 1 ? this.sick_leaves.selectedCondition.value : 0
                    objData.is_particular_condition = this.sick_leaves.selectedCondition.id  == 2 ? this.sick_leaves.selectedCondition.value : 0
                    objData.is_special_condition = this.sick_leaves.selectedCondition.id  == 3 ? this.sick_leaves.selectedCondition.value : 0
                    objData.sick_leave_urgent_id = this.sick_leaves.selectUrgentId ? this.sick_leaves.selectUrgentId.id : ''
                    objData.sick_leave_infectious_disease_id = this.sick_leaves.selectInfectionDisease ? this.sick_leaves.selectInfectionDisease.id : ''
                    objData.sick_leave_infectious_disease_isolation_id = this.sick_leaves.selectInfectionDiseaseIsolation ? this.sick_leaves.selectInfectionDiseaseIsolation.id : ''
                    objData.employer_days = this.sick_leaves.employer_days
                    objData.insurance_days = this.sick_leaves.insurance_days
                    objData.calendar_days = this.sick_leaves.calendar_days
                    objData.working_days = this.sick_leaves.working_days
                    objData.daily_average_amount = this.sick_leaves.daily_average_amount ? this.sick_leaves.daily_average_amount.replace(/,/g, '') : this.sick_leaves.daily_average_amount;
                    objData.realised_income_max_limit = this.sick_leaves.realised_income_max_limit ? parseInt(this.sick_leaves.realised_income_max_limit.replace(/,/g, '')) : this.sick_leaves.realised_income_max_limit;
                    objData.calculation_base_amount = Number(this.sick_leaves.calculation_base_amount)
                    objData.calculation_base_days = this.sick_leaves.calculation_base_days
                    objData.employer_amount = this.sick_leaves.employer_amount ? parseInt(this.sick_leaves.employer_amount.replace(/,/g, '')) : this.sick_leaves.employer_amount;
                    objData.insurance_amount = this.sick_leaves.insurance_amount ? parseInt(this.sick_leaves.insurance_amount.replace(/,/g, '')) : this.sick_leaves.insurance_amount;
                    objData.is_modified_divided_days = this.sick_leaves.is_modified_divided_days
                    objData.is_modified_realised_base = this.sick_leaves.is_modified_realised_base
                    objData.realised_incomes = this.sick_leaves.realised_incomes

					let arrayErrors = Object.keys(objData)

					if(type == 'create'){
						this.createMedical(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader, arrayErrors)
					}else if(type == 'update'){
						this.updateMedical(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader, arrayErrors)
					}

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createMedical(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader, arrayErrors){
				axios.post('/sick-leaves/store', objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$route.query.from_salary ? this.$router.push({ name: 'sick-leaves' }) : this.$router.push({ name: 'contract-show', params: {id: this.$route.params.contract_id ? this.$route.params.contract_id : this.sick_leaves.contractSelected.id}, query: {tab: 'sick-leaves'} })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}

							this.error_message = Object.values(error.response.data.errors).join('')
							
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			},

			updateMedical(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader, arrayErrors) {
				axios.patch(`/sick-leaves/${this.$route.params.id}`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$route.query.from_salary ? this.$router.push({ name: 'sick-leaves' }) : this.$router.push({ name: 'contract-show', params: {id: this.$route.params.contract_id}, query: {tab: 'sick-leaves'} })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							// this.error_message = Object.values(error.response.data.errors).join('\r\n')
							this.error_message = Object.values(error.response.data.errors).join('')
							
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			},
            showModal(type, data = false){
				this.$root.$emit('open_modal', type, data);
			},
        }
	}
</script>

<style lang="scss">
    .bg-gray {
        background: #f1f1f1;
    }
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	.contract-modification-name {
		color: #45a0ff;
		font-weight: 500;
		font-size: 18px;
	}
</style>